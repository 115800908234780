.Footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
}

.social-media-icon {
  padding: 0.25em;
}

.social-media-icon:hover {
  transform: scale(1.2);
}

.link {
  text-decoration: none;
  color: #ffffff;
  transition: 250ms ease-in-out;
}

.link:hover {
	color: #ffffffaa;
  transition: 250ms ease-in-out;
}