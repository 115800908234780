h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.Navbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #282c34;
  padding: 0.5em;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
  z-index: 2;
}

.Navbar-mini {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #282c34;
  padding: 0.5em;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
  z-index: 2;
}

.Navbar-mini:hover {
  cursor: pointer;
}

.Navbar-item-right {
  display: flex;
  justify-content: flex-end;
}

.Navbar-item-left {
  display: flex;
  justify-content: flex-start;
}

.link {
  text-decoration: none;
  color: #ffffff;
  transition: 250ms ease-in-out;
}

.nav-link {
  padding: 0.25em;
  right: 0px;
  text-decoration: none;
  color: #ffffff;
  transition: 250ms ease-in-out;
}
.nav-link:hover {
  /* color: #dedede; */
  /* background-color: #4f4f4f; */

  background-color: #dedede;
  color: #282c34;
  border-radius: 5px;
}

.nav-link.active {
  transition: 250ms ease-in-out;
  background-color: #ffffff;
  color: #282c34;
  border-radius: 5px;
}

.social-media-icon {
  padding: 0.25em;
}

/* .social-media-icon:hover {
  transform: scale(2);
} */

.link:hover {
  transition: 250ms ease-in-out;
  color: #ffffffaa;
}

.logo {
  max-inline-size: calc(50px);
  position: absolute;
  left: 0;
  top: 0;
}

/* .page {
  padding: 1.5em;
  z-index: 1;
} */

.date {
  color: #ff0000;
}
