.code-skills {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
	width: 50px;
	height: auto;
  padding: 5px;
  margin: 1px;
  border: 1px solid grey;
  border-radius: 5px;
  transition: 250ms ease-in-out;
}
.svg:hover {
  transition: 250ms ease-in-out;
  transform: scale(105%);
  border: 1px solid white;
}