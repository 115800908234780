.greeting {
	/* color: rgba(255, 0, 0, 0.87); */
  transition: 500ms ease-in-out;
	size: 50px;
	font-size: 50px;
	/* webki: 500ms ease-in-out; */
	/* MozTransition: "all 1 ease"; */
	/* animation: fadeIn 1s;  */
	animation: fadeIn 1s; 
	animation: fadeOut 1s; 
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
